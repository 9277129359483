<template>
  <section class="search-advance">
    <div v-if="loading" class="m-auto t-center">
      <span class="spinner mx-auto mb-2"></span>
      <h2>{{ $t('Loading') }}...</h2>
    </div>
    <div v-else class="container-fluid">
      <img
        class="search-advance__banner"
        src="@/assets/img/feeling_lucky.jpg"
        alt="Feeling lucky"
      />
      <div class="search-advance__container">
        <h2>
          <span v-text="$t('Find the book you have in mind!')"></span>
        </h2>
      </div>
      <div class="search-advance__card">
        <div class="search-advance__container">
          <p class="search-advance__text" v-text="$t('Subtitle')"></p>

          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="search-advance__genre">
              <div class="form-group form-group--horizontal">
                <label
                  slot="label"
                  class="form-label"
                  for="search-genre"
                  @click="setFocusOnGenre"
                  v-text="$t('Genre')"
                ></label>
                <MultiselectUnderline
                  id="search-genre"
                  v-model="form.genres"
                  class="form-select__underline"
                  :value="form.genres"
                  :options="genres"
                  v-bind="$attrs"
                  track-by="id"
                  label="name"
                  :multiple="true"
                  :taggable="false"
                  :hide-selected="true"
                  :placeholder="$t('Select a genre')"
                  :tag-placeholder="$t('Press enter to create a new genre')"
                  :custom-label="customGenreLabel"
                  v-on="$listeners"
                  @tag="addGenre"
                />
              </div>
              <div class="form-group form-group--horizontal">
                <div class="w-auto">
                  <label
                    for="search-place"
                    class="form-label"
                    v-text="$t('Place')"
                  >
                  </label>
                </div>
                <div class="w-100">
                  <gmap-autocomplete
                    id="search-place"
                    class="field-map__input form-group form-control form-input--underline"
                    :value="place"
                    @input="changePlace"
                    @place_changed="setPlace"
                    :placeholder="$t('Type a position')"
                  >
                  </gmap-autocomplete>
                  <div
                    v-if="form.places && form.places.length"
                    class="places__list"
                  >
                    <basic-tag
                      v-for="pl in form.places"
                      :id="pl"
                      :key="pl"
                      :text="pl"
                      @deleteItem="removePlace"
                    ></basic-tag>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex ml-sm-auto flex-column flex-md-row">
              <div class="search-advance__time">
                <div class="d-flex justify-content-sm-end">
                  <div class="form-group form-group--horizontal mb-2">
                    <label
                      class="form-label"
                      for="from"
                      v-text="$t('Time')"
                    ></label>
                    <the-mask
                      id="from"
                      v-model="form.from"
                      mask="#####"
                      type="text"
                      :masked="false"
                      :placeholder="$t('From')"
                      class="form-control form-input--underline"
                    ></the-mask>
                  </div>
                  <div class="form-group search-advance__epoch mb-2">
                    <button
                      type="button"
                      :class="{ checked: form.isFromBc }"
                      @click="form.isFromBc = true"
                      v-text="$t('BC')"
                    ></button>
                    <button
                      type="button"
                      :class="{ checked: !form.isFromBc }"
                      @click="form.isFromBc = false"
                      v-text="$t('AD')"
                    ></button>
                  </div>
                </div>
                <div class="d-flex justify-content-sm-end">
                  <div class="form-group form-group--horizontal">
                    <label for="to" class="form-label"></label>
                    <the-mask
                      id="to"
                      v-model="form.to"
                      mask="#####"
                      type="text"
                      :masked="false"
                      :placeholder="$t('To')"
                      class="form-control form-input--underline"
                    ></the-mask>
                  </div>
                  <div class="form-group search-advance__epoch">
                    <button
                      type="button"
                      :class="{ checked: form.isToBc }"
                      @click="form.isToBc = true"
                      v-text="$t('BC')"
                    ></button>
                    <button
                      type="button"
                      :class="{ checked: !form.isToBc }"
                      @click="form.isToBc = false"
                      v-text="$t('AD')"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="search-advance__year">
                <div class="form-group d-flex align-items-end mb-2">
                  <basic-checkbox
                    v-model="form.isExactYear"
                    class="t-semibold"
                    :label="$t('Exact year')"
                    value="isExactYear"
                    @change="setFocusOnExactYearInput($event)"
                  ></basic-checkbox>
                </div>
                <div v-if="form.isExactYear" class="d-flex">
                  <div class="form-group form-group--horizontal">
                    <the-mask
                      ref="exactYearInput"
                      v-model="form.exactYear"
                      mask="#####"
                      type="text"
                      :masked="false"
                      :placeholder="$t('Year')"
                      class="form-control form-input--underline"
                    ></the-mask>
                  </div>
                  <div class="form-group search-advance__epoch">
                    <button
                      type="button"
                      :class="{ checked: form.isExactYearBc }"
                      @click="form.isExactYearBc = true"
                      v-text="$t('BC')"
                    ></button>
                    <button
                      type="button"
                      :class="{ checked: !form.isExactYearBc }"
                      @click="form.isExactYearBc = false"
                      v-text="$t('AD')"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div
                class="form-group form-group--horizontal search-advance__book-tags"
              >
                <div class="w-auto">
                  <label for="tags" class="form-label">
                    <span>{{ $t('Tags') }}</span>
                    <br />
                    <span class="t-small t-normal">({{ $t('up to') }} 5)</span>
                  </label>
                </div>
                <div class="w-100">
                  <input
                    id="tags"
                    v-model="tag"
                    type="text"
                    class="form-control form-input--underline"
                    :placeholder="$t('Anything you have in mind…')"
                    maxlength="70"
                    @blur="addTag"
                    @keypress="addTag"
                  />
                  <span
                    v-for="tog in form.tags"
                    :key="tog"
                    class="tag"
                    :title="tog"
                  >
                    <span v-text="tog"></span>
                    <span
                      class="tag__close"
                      @click="removeFormValue(tog, 'tags')"
                    ></span>
                  </span>
                  <!--                  <pre>{{tag.length}} / 100</pre>-->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mt-4">
                <div class="form-group d-flex align-items-center mb-0">
                  <basic-checkbox
                    v-model="form.isRealCharacters"
                    class="t-bold"
                    :label="$t('Real characters')"
                    :limit="3"
                    value="isRealCharacters"
                    @change="setFocus($event, 'realCharactersInput')"
                  ></basic-checkbox>
                  <TooltipInfo :content="$t('realCharactersInfo')" />
                </div>
                <div v-if="form.isRealCharacters" class="form-group mt-1 mb-0">
                  <input
                    id="search-real-characters"
                    ref="realCharactersInput"
                    v-model="realCharacter"
                    type="text"
                    class="form-control form-input--underline"
                    @blur="addRealCharacter"
                    @keypress="addRealCharacter"
                  />
                  <div>
                    <span
                      v-for="event in form.realCharacters"
                      :key="event"
                      class="tag"
                    >
                      <span v-text="event"></span>
                      <span
                        class="tag__close"
                        @click="removeFormValue(event, 'realCharacters')"
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="form-group d-flex align-items-center mb-0">
                  <basic-checkbox
                    v-model="form.isCharacterNames"
                    class="t-bold"
                    :label="$t('Characters\' names')"
                    :limit="3"
                    value="isCharacterNames"
                    @change="setFocus($event, 'characterNamesInput')"
                  ></basic-checkbox>
                  <TooltipInfo :content="$t('characterNamesInfo')" />
                </div>
                <div v-if="form.isCharacterNames" class="form-group mb-0 mt-1">
                  <input
                    id="search-character"
                    ref="characterNamesInput"
                    v-model="characterName"
                    type="text"
                    class="form-control form-input--underline"
                    @keypress="addCharacterName"
                    @blur="addCharacterName"
                  />
                  <div>
                    <span
                      v-for="event in form.characterNames"
                      :key="event"
                      class="tag"
                    >
                      <span v-text="event" />
                      <span
                        class="tag__close"
                        @click="removeFormValue(event, 'characterNames')"
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mt-4">
                <div class="form-group d-flex align-items-center mb-0">
                  <basic-checkbox
                    v-model="form.isTrueEvents"
                    :limit="3"
                    class="t-bold"
                    :label="$t('True events')"
                    value="isTrueEvents"
                    @change="setFocus($event, 'trueEventsInput')"
                  ></basic-checkbox>
                  <TooltipInfo :content="$t('trueEventsInfo')" />
                </div>
                <div v-if="form.isTrueEvents" class="form-group mt-1 mb-0">
                  <input
                    id="search-real-event"
                    ref="trueEventsInput"
                    v-model="trueEvent"
                    type="text"
                    class="form-control form-input--underline"
                    @blur="addRealEvent"
                    @keypress="addRealEvent"
                  />
                  <div>
                    <span
                      v-for="event in form.trueEvents"
                      :key="event"
                      class="tag"
                    >
                      <span v-text="event"></span>
                      <span
                        class="tag__close"
                        @click="removeFormValue(event, 'trueEvents')"
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="form-group d-flex align-items-center mb-0">
                  <basic-checkbox
                    v-model="form.isScreenAdaptations"
                    class="t-bold"
                    :label="$t('Screen adaptation')"
                    value="isScreenAdaptations"
                    @change="setFocus($event, 'screenAdaptationInput')"
                  ></basic-checkbox>
                  <TooltipInfo :content="$t('screenAdaptationInfo')" />
                </div>
                <div
                  v-if="form.isScreenAdaptations"
                  class="form-group d-flex mt-1 mb-0"
                >
                  <SingleSelectUnderline
                    v-model="form.screenAdaptationCategory"
                    class="screen-adaptation__select"
                    track-by="id"
                    label="name"
                    :options="screenAdaptationCategories"
                    :allow-empty="false"
                  />
                  <div class="ml-2 w-100">
                    <input
                      ref="screenAdaptationInput"
                      v-model="screenAdaptation"
                      type="text"
                      class="form-control form-input--underline"
                      @keypress="addScreenAdaptation"
                      @blur="addScreenAdaptation"
                    />
                    <div>
                      <span
                        v-for="adaptation in form.screenAdaptations"
                        :key="adaptation"
                        class="tag"
                      >
                        <span v-text="adaptation" />
                        <span
                          class="tag__close"
                          @click="
                            removeFormValue(adaptation, 'screenAdaptations')
                          "
                        ></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-group">
            <button
              type="button"
              class="btn btn--primary btn--search"
              :class="{ 'btn--disabled': isEmptyRequest }"
              @click="search()"
              v-text="$t('Search books')"
            ></button>
            <button
              type="button"
              class="btn btn--perfect-match"
              @click="search(true)"
            >
              <span>{{ $t('Perfect match') }}</span>
              <TooltipInfo :content="$t('perfectMatchInfo')" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n src="./translations.json"></i18n>

<script>
import { TheMask } from 'vue-the-mask';
import sortby from 'lodash.sortby';
import helpers from '@/helpers/helpers';
import BasicTag from '@/components/ui/basicTag/BasicTag';
import BasicCheckbox from '@/components/ui/basic-checkbox/BasicCheckbox';
import MultiselectUnderline from '@/components/ui/multiselect/MultiselectUnderline';
import SingleSelectUnderline from '@/components/ui/singleselect/SingleSelectUnderline';
import TooltipInfo from '@/components/ui/tooltipInfo/TooltipInfo';
import { SCREEN_ADAPTATIONS } from './constants';

export default {
  components: {
    BasicTag,
    BasicCheckbox,
    MultiselectUnderline,
    SingleSelectUnderline,
    TheMask,
    TooltipInfo,
  },

  data() {
    const screenAdaptationCategories = SCREEN_ADAPTATIONS.map((name) => ({
      name: this.$t(name),
      id: name,
    }));

    return {
      form: {
        exactYear: null,
        from: null,
        to: null,
        places: [],
        googlePlaceIds: [],
        tags: [],
        genres: [],
        realCharacters: [],
        trueEvents: [],
        characterNames: [],
        screenAdaptations: [],
        screenAdaptationCategory: screenAdaptationCategories[0],
        isExactYear: false,
        isTrueEvents: false,
        isRealCharacters: false,
        isCharacterNames: false,
        isScreenAdaptations: false,
        isFromBc: false,
        isToBc: false,
        isExactYearBc: false,
      },
      place: '',
      trueEvent: '',
      realCharacter: '',
      characterName: '',
      screenAdaptation: '',
      tag: '',
      genres: [],
      loading: false,
      screenAdaptationCategories,
    };
  },

  computed: {
    isExistTrueEvent() {
      return this.form.trueEvents.find(
        (value) => value.trim() === this.trueEvent.trim()
      );
    },

    isExistCharacterName() {
      return this.form.characterNames.find(
        (value) => value.trim() === this.characterName.trim()
      );
    },

    isExistScreenAdaptation() {
      return this.form.screenAdaptations.find(
        (value) => value.trim() === this.screenAdaptation.trim()
      );
    },

    isExistTag() {
      return this.form.tags.find((value) => value.trim() === this.tag.trim());
    },

    isEmptyRequest() {
      const defaults = ['screenAdaptationCategory'];

      return !Object.keys(this.form)
        .filter((key) => !defaults.includes(key))
        .some((key) => this.form[key] && this.form[key].length);
    },

    searchParams() {
      const params = {};
      const keys = Object.keys(this.form);

      keys.forEach((key) => {
        if (this.form[key] instanceof Array && this.form[key].length) {
          const options = [];
          this.form[key].forEach((item) => {
            options.push(item.id ?? item.name ?? item);
          });

          params[key] = options.join(',');
        } else if (
          (typeof this.form[key] === 'string' && this.form[key].length) ||
          typeof this.form[key] === 'boolean'
        ) {
          params[key] = this.form[key];
        } else if (this.form[key] && this.form[key].id) {
          params[key] = this.form[key].id;
        }
      });

      return params;
    },
  },

  watch: {
    tag(v) {
      if (v.length <= 70) {
        this.tag = v;
      }
      this.tag = v.slice(0, 70);
    },
  },

  async mounted() {
    this.loading = true;

    await this.$store.dispatch('fetchConstants');

    this.genres = sortby(this.$store.state.constants.genres, 'name');

    this.loading = false;
  },

  methods: {
    changeFormInfo(field, valid) {
      const pluralFields = [
        'tags',
        'characterNames',
        'trueEvents',
        'realCharacters',
        'screenAdaptations',
      ];
      return ({ keyCode, target, type }) => {
        const { value } = target;
        const withUpper = helpers.toFirstUpperCase(value);
        if ((keyCode === 13 || type === 'blur') && withUpper && !valid) {
          if (!this.form[field].includes(withUpper)) {
            this.form[field].push(withUpper);
          }
          if (pluralFields.includes(field)) {
            const f = field.substring(0, field.length - 1);
            this[f] = '';
          }
          this[field] = '';
        }
      };
    },

    addGenre(id) {
      this.form.genres.push({ name: this.$t(id), id });
    },

    setPlace(event) {
      const { address_components: components } = event;
      const country = components.find(({ types }) =>
        types.some((t) => ['country', 'continent'].includes(t))
      );
      const postalTown = components.find(({ types }) =>
        types.includes('locality')
      );

      const countryName = country ? country.long_name : '';
      const postalTownName = postalTown ? postalTown.long_name : '';
      this.form.places.push(`${postalTownName} ${countryName}`.trim());
      this.form.googlePlaceIds.push(event.place_id);
      this.place = '';
    },

    removePlace(id) {
      const index = this.form.places.findIndex((pl) => pl === id);
      this.form.places.splice(index, 1);
      this.form.googlePlaceIds.splice(index, 1);
    },

    changePlace(event) {
      const { value } = event.target;
      this.place = value;
    },

    addRealCharacter(value) {
      if (this.form.realCharacters.length >= 3) {
        return;
      }

      this.changeFormInfo('realCharacters', this.isExistRealCharacter)(value);
    },

    addRealEvent(value) {
      if (this.form.trueEvents.length >= 3) {
        return;
      }

      this.changeFormInfo('trueEvents', this.isExistTrueEvent)(value);
    },

    addCharacterName(value) {
      if (this.form.characterNames.length >= 3) {
        return;
      }

      this.changeFormInfo('characterNames', this.isExistCharacterName)(value);
    },

    addScreenAdaptation(value) {
      this.changeFormInfo(
        'screenAdaptations',
        this.isExistScreenAdaptation
      )(value);
    },

    addTag(value) {
      if (this.form.tags.length >= 5) {
        return;
      }

      this.changeFormInfo('tags', this.isExistTag)(value);
    },

    removeFormValue(value, field) {
      this.form[field] = this.form[field].filter((el) => el !== value);
    },

    search(perfect = false) {
      if (!this.isEmptyRequest) {
        const trueGenres = this.form.genres.map(
          (g) => this.genres.find(({ name }) => name === g.name).id
        );

        const route = this.$localePath('books', null, {
          ...this.searchParams,
          genres: trueGenres,
          isPerfectMatch: perfect,
        });
        this.$router.push(route);
      }
    },

    customGenreLabel(label) {
      return this.$t(label.name);
    },

    setFocusOnGenre() {
      this.$nextTick(() => {
        const el = this.$el.querySelector('#search-genre input');

        if (el) {
          el.focus();
        }
      });
    },

    setFocusOnExactYearInput(opened) {
      if (opened) {
        this.$nextTick(() => this.$refs.exactYearInput.$el.focus());
      }
    },

    setFocus(opened, ref) {
      if (opened) {
        this.$nextTick(() => this.$refs[ref] && this.$refs[ref].focus());
      }
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import '@/assets/styles/_tags.scss';
@import './styles.scss';
</style>
