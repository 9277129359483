<template>
  <div v-tooltip="content" class="info-icon">
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324961 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03682 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48913 0 7.5 0ZM7.45313 2.475C7.63855 2.475 7.8198 2.52998 7.97397 2.633C8.12815 2.73601 8.24831 2.88243 8.31926 3.05373C8.39022 3.22504 8.40879 3.41354 8.37261 3.5954C8.33644 3.77725 8.24715 3.9443 8.11604 4.07541C7.98493 4.20652 7.81788 4.29581 7.63602 4.33199C7.45417 4.36816 7.26567 4.34959 7.09436 4.27864C6.92306 4.20768 6.77664 4.08752 6.67362 3.93335C6.57061 3.77918 6.51563 3.59792 6.51563 3.4125C6.51563 3.16386 6.6144 2.9254 6.79021 2.74959C6.96603 2.57377 7.20449 2.475 7.45313 2.475ZM9.14063 12.4359H5.85938C5.68533 12.4359 5.51841 12.3668 5.39534 12.2437C5.27227 12.1207 5.20313 11.9537 5.20313 11.7797C5.20313 11.6056 5.27227 11.4387 5.39534 11.3156C5.51841 11.1926 5.68533 11.1234 5.85938 11.1234H6.84375V6.81094H6.09375C5.9197 6.81094 5.75278 6.7418 5.62971 6.61873C5.50664 6.49565 5.4375 6.32873 5.4375 6.15469C5.4375 5.98064 5.50664 5.81372 5.62971 5.69065C5.75278 5.56758 5.9197 5.49844 6.09375 5.49844H8.15625V11.1234H9.14063C9.31467 11.1234 9.4816 11.1926 9.60467 11.3156C9.72774 11.4387 9.79688 11.6056 9.79688 11.7797C9.79688 11.9537 9.72774 12.1207 9.60467 12.2437C9.4816 12.3668 9.31467 12.4359 9.14063 12.4359Z"
        fill="#F7B058"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TooltipInfo',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.info-icon {
  align-content: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}
</style>
